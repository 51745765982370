<template>
    <vs-card class="h-full pl-1">
      <!-- Header -->
      <div slot="header" class="flex w-full justify-between">
        <h3>Promotions</h3>
        <div>
          <vs-button class=" mr-2 w-24" @click="save"> Save </vs-button>
          <vs-button class="ml-auto" @click="addRow"> Add Row </vs-button>
        </div>
      </div>
      <vs-input
        label="Slug"
        class="mt-5 md:w-1/2 w-full"
        v-model="products_slug"
      />
  
      <vs-divider />
      <!-- Cards -->
      <div v-for="(row, rowKey) in rows" :key="rowKey">

        <vs-card>
          <div slot="header" class="flex w-full justify-between">
            <h4>Row {{ rowKey + 1 }}</h4>
            <div>
              <vs-button v-if="row.length < 2" class="mr-2" @click="addCard(rowKey)"> Add Card </vs-button>
              <vs-button class="ml-auto" color="danger" @click="removeRow(rowKey)"> Remove Row </vs-button>
            </div>
          </div>
  
          <vs-card v-for="(card, key) in row" :key="key">
            <div slot="header" class="flex w-full justify-between">
              <h4>Card {{ key + 1 }}</h4>
              <div>
                <vs-button v-if="row.length > 1" class="ml-auto" color="danger" @click="removeCard(rowKey, key)"> Remove Card </vs-button>
              </div>
            </div>
            <vs-input
              label="Title"
              class="mt-5 md:w-1/2 w-full"
              v-model="card.title"
            />
            <div class="mt-5">
              <label style="font-size: .85rem; padding-left: 5px;">Description</label>
              <vs-textarea
                class=" md:w-1/2 w-full"
                v-model="card.description"
              />
            </div>
            <vs-input
              label="Button Text"
              class="mt-4 md:w-1/2 w-full"
              v-model="card.button_text"
            />
            <vs-input
              label="Button Link"
              class="mt-4 mb-3 md:w-1/2 w-full"
              v-model="card.link"
              v-validate="card.button_text ? 'required' : ''"
              :data-vv-name="'button_link_' + rowKey + '_' + key"
              data-vv-as="Button link"
              :success="!errors.first('button_link_' + rowKey + '_' + key) && card.link !== ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="!!errors.first('button_link_' + rowKey + '_' + key)"
              :danger-text="errors.first('button_link_' + rowKey + '_' + key)"
            />

            <vs-input
              label="Image Link"
              class="mt-4 mb-3 md:w-1/2 w-full"
              v-model="card.image_link"
            />

            <label class="text-sm ml-1 mt-4">Desktop Image</label>
            <br />
            <div v-if="card.desktop_image_url">
              <img :src="card.desktop_image_url" alt class="w-full h-auto max-w-md" />
              <br />
            
              <vs-button
                class="bg-primary2 my-2"
                @click="handleRemoveImage('desktop', card)"
                >Remove Image</vs-button
              >
            </div>
            <div v-else>
              <input
                type="file"
                @change="handleFileChange($event, 'desktop', card)"
                accept="image/*"
              />
            </div>

            <label class="text-sm ml-1 mt-3">Mobile Image</label>
            <br />
            <div v-if="card.mobile_image_url">
              <img :src="card.mobile_image_url" alt class="w-full h-auto max-w-md" />
              <br />
              <vs-button
                class="bg-primary2 my-2"
                @click="handleRemoveImage('mobile', card)"
                >Remove Image</vs-button
              >
            </div>
            <div v-else>
              <input
                type="file"
                @change="handleFileChange($event, 'mobile', card)"
                accept="image/*"
              />
            </div>

          </vs-card>
        </vs-card>
      </div>
      <legend>The square brackets <code>[ ]</code> in the URL, such as <code>[nurse]</code>, are placeholders that will be automatically replaced with the appropriate user type (e.g., <code>nurse</code>, <code>doctor</code>) when processed.</legend>
    </vs-card>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import axios from "@/axios";
  
  export default {
    data() {
      return {
        products_slug: "",
        rows: [],
        cardDetails: {
          button_text: "",
          description: "",
          desktop_image_url: "",
          link: "",
          mobile_image_url: "",
          title: ""
        }
      };
    },
  
    methods: {
      ...mapActions("eCommerce", ["saveJsonSettings", "getJsonSettings"]),
      async save() {
        const isValid = await this.$validator.validate();
        if(isValid){
          this.$vs.loading({
            text: "Saving...",
            color: "#3dc9b3",
            background: "white",
          });
          const slug = "promotions";
          const value = { products_slug: this.products_slug, cards: this.rows };
          this.saveJsonSettings({ value, slug })
            .then((val) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Success",
                text: "Settings Saved.",
                color: "success",
              });
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Failed to save settings.",
                text: "Please try again later.",
                color: "danger",
              });
              console.error(err);
            });
              this.$vs.loading.close();
        } else {
          this.scrollToFirstError();
        }
  
      },
      scrollToFirstError() {
        //This function is responsible for automatically scrolling to the first field that contains an error.

        this.$nextTick(() => {
            const firstErrorMessage = Array.from(document.querySelectorAll('.span-text-validation'))
                .find((el) => el.innerText.trim() !== '');
            if (firstErrorMessage) {
              const inputField = firstErrorMessage.closest('.vs-input, .vs-textarea');
              if (inputField) {
                inputField.scrollIntoView({ behavior: "smooth", block: "center" });
                const inputElement = inputField.querySelector('input, textarea');
                if (inputElement) inputElement.focus();
              }
            }
        });
      },
      async handleRemoveImage(imgType, card) {
        if (imgType === "desktop") card.desktop_image_url = "";
        if (imgType === "mobile") card.mobile_image_url = "";
      },
      uploadImage(event, imgType, card, key = "profileImage") {
        this.$vs.loading({
          text: "Uploading...",
          color: "#3dc9b3",
          background: "white",
        });
        let data = new FormData();
        data.append("profileImage", event);
        let config = {
          header: {
            "Content-Type": "image/png",
          },
        };
        axios
          .post("/api/v1/doctors/upload?key=" + key, data, config)
          .then((res) => {
            if (imgType === "desktop") card.desktop_image_url = res.data.Location;
            if (imgType === "mobile") card.mobile_image_url = res.data.Location;
            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            if (err && err.status === 415) {
              this.$vs.notify({
                title: "Upload Failed",
                text: "File Format Not Supported",
                color: "danger",
              });
            } else {
              this.$vs.notify({
                title: "Upload Failed",
                text: "Please try again later.",
                color: "danger",
              });
            }
          });
      },
      handleFileChange(event, imgType, card) {
        this.uploadImage(event.target.files[0], imgType, card);
      },
      addRow(){
        const newCard = { ...this.cardDetails };
        this.rows = [...this.rows, [newCard]]
        this.scrollToBottom()
      },
      addCard(key){
        const newCard = { ...this.cardDetails };
        this.rows[key].push(newCard)
      },
      removeRow(key){
        this.rows.splice(key, 1);
      },
      removeCard(rowKey, cardKey){
        this.rows[rowKey].splice(cardKey, 1);
      },
      scrollToBottom() {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }
    },
    async created() {
      this.getJsonSettings({ slug: "promotions" }).then((val) => {
        this.rows = val.data.data.cards
        this.products_slug = val.data.data.products_slug;
      });
    },
  };
  </script>